import React from 'react'
import Login from 'components/pages/Login'
import { inject, observer } from 'mobx-react'
import { mountHook } from 'uikit'

const Page = ({ AuthModel }) => {
  mountHook(() => {
    AuthModel.CommonModel.applyUnknownHistory()
  })

  return (
    <Login/>
  )
}

export default inject('AuthModel')(observer(Page))
