import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Block, Image, Text, Input, Button, Icon, Focusable, Underline, phoneNumberNormalizer } from 'uikit'
import styled from 'styled-components'
import background from 'resources/img/background4.jpg'
import sfinanceText from 'resources/img/sfinance-text.svg'
import sfinanceTextPng from 'resources/img/sfinance-text-new-year.png'
import { authHtmlID } from 'utils/htmlID'
import Link from 'components/Link'
import Head from 'next/head'
import { documentTitle } from 'utils/document'
import { SUPPORT_PHONE, SUPPORT_EMAIL } from 'containers/PageContainer/Wrapper/constants'

const Root = styled(Block)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("${background}") no-repeat center fixed;     
  background-size: cover;
`

const LogoContainer = styled(Block)`
  margin-top: 80px;
  margin-bottom: 80px;

  ${props => props.theme.viewPorts.mobile} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`

const CustomValidation = styled(Text)`
  position: absolute;
  margin-top: -18px;
`

const MainBlock = styled(Block)`
  width: 510px;

  ${props => props.theme.viewPorts.mobile} {
    width: auto;
    margin: 0 16px 32px 16px;
  }
`

const TitleContainer = styled(Block)`
  padding: 24px 40px;
`

const Title = styled(Text)`
  font-size: 30px;
  font-weight: 500;
  text-align: center;

  ${props => props.theme.viewPorts.mobile} {
    font-size: 24px;
  }
`

const Controls = styled(Block)`
  padding: 0 40px 20px 40px;

  ${props => props.theme.viewPorts.mobile} {
    padding: 0 16px 24px 16px;
  }
`

const Container = styled(Block)`
  border-radius: 28px;
  box-shadow: 0 1px 6px 0 #e8ebef, 0 1px 2px 0 rgba(186, 196, 205, 0.16);
  background-color: #F4F7FF;
`

const Login = ({ AuthModel }) => {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isForceValidate, setIsForceValidate] = useState(false)

  const handleSignIn = async () => {
    if (AuthModel.CommonModel.validLogin || AuthModel.CommonModel.validPassword) {
      setIsForceValidate(true)
      return
    }
    await AuthModel.CommonModel.signIn()
  }

  return (
    <Root>
      <Head>
        <title>{documentTitle('Авторизация')}</title>
      </Head>

      <LogoContainer isCenter>
        <Image src={sfinanceText} height={150} width={300} isRem={false}/>
      </LogoContainer>
      <MainBlock>
        <Container isError={AuthModel.CommonModel.isFailedSignIn}>
          <TitleContainer isCenter>
            <Title type="gray6">Вход в личный кабинет</Title>
          </TitleContainer>
          <Controls>
            <Block>
              <form>
                <Input
                  label="Логин или email"
                  value={AuthModel.CommonModel.login}
                  onChange={AuthModel.CommonModel.setLogin}
                  error={AuthModel.CommonModel.validLogin}
                  isShowForceValidate={isForceValidate}
                  isDisable={AuthModel.CommonModel.isProcessSignIn}
                  isMarginBottom
                  name="username"
                  isAutoComplete
                  onPressEnter={handleSignIn}
                  htmlID={authHtmlID.username}
                  tabIndex={1}
                />
                <Input
                  label="Пароль"
                  type={isShowPassword ? 'text' : 'password'}
                  value={AuthModel.CommonModel.password}
                  onChange={AuthModel.CommonModel.setPassword}
                  error={AuthModel.CommonModel.validPassword}
                  isShowForceValidate={isForceValidate}
                  isDisable={AuthModel.CommonModel.isProcessSignIn}
                  marginTop={25}
                  isRem={false}
                  name="password"
                  isAutoComplete
                  onPressEnter={handleSignIn}
                  postfix={(
                    <Focusable
                      tabIndex={0}
                      padding={0.5}
                      onClick={() => { setIsShowPassword(!isShowPassword) }}
                    >
                      <Icon icon="eye" iconType="redesign" color="primary"/>
                    </Focusable>
                  )}
                  htmlID={authHtmlID.password}
                  tabIndex={1}
                />
              </form>
              {AuthModel.CommonModel.isFailedSignIn && <CustomValidation type="danger">Неверный логин или пароль</CustomValidation>}
            </Block>

            <Button
              marginTop={30}
              isRem={false}
              type="primary"
              width="100%"
              isLoading={AuthModel.CommonModel.isProcessSignIn}
              onClick={handleSignIn}
              htmlID={authHtmlID.signIn}
            >
              Войти
            </Button>
          </Controls>
        </Container>

        <Block style={{
          textAlign: 'center'
        }} marginTop={16} isRem={false}>
          <Link uri="/restore_password">
            <Underline
              htmlID={authHtmlID.restorePassword}
              style={{ color: 'white' }}
              color="white"
            >
              Забыли пароль?
            </Underline>
          </Link>
        </Block>
        <Block style={{
          textAlign: 'center'
        }} marginTop={16} isRem={false}>
          <Text color="white" style={{ color: 'white' }}>
            Техподдержка:&nbsp;
            <a href={`tel: 8${SUPPORT_PHONE}`}>8 {phoneNumberNormalizer(SUPPORT_PHONE)}</a>,&nbsp;
            <a href={`mailto: ${SUPPORT_EMAIL}`}>
              <Underline
                style={{ color: 'white' }}
                color="white"
              >
                {SUPPORT_EMAIL}
              </Underline>
            </a>
          </Text>
        </Block>
      </MainBlock>
    </Root>
  )
}

export default inject(store => ({
  AuthModel: store.AuthModel
}))(observer(Login))
